import { MatSnackBar } from '@angular/material/snack-bar';
import { JwtHelperService } from '@auth0/angular-jwt';
import { of, throwError, Observable, BehaviorSubject } from 'rxjs';

import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { SessionStorageManagementService } from './session-storage-management.service';
import { ConfigService } from './config.service';
import { LoggerService } from './logger.service';
import { LogoutService } from './logout.service';
import { CheckSaveChangesService } from './check-save-changes.service';

import { UnmanagedCookieService } from 'ng2-cookies';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { Router } from '@angular/router';
import { UserConfigDto } from './server-data';
import { environment } from '../../environments/environment';

class TokenResult {
	// tslint:disable-next-line:variable-name
	token_type: string;
	// tslint:disable-next-line:variable-name
	access_token: string;
	// tslint:disable-next-line:variable-name
	expires_in: number;
	// tslint:disable-next-line:variable-name
	refresh_token: string;
}

class UserInfo {
	constructor(public username: string, public token: string) { }
}

export class LoginResult {
	errorMessage: string;
	ok: boolean;
	forceReload: boolean;
	passwordExpired: boolean;

	static OK(): LoginResult {
		return <LoginResult>{
			ok: true,
		};
	}

	static Error(message: string): LoginResult {
		return <LoginResult>{
			errorMessage: message,
			ok: false,
		};
	}

	static PasswordExpired(message: string): LoginResult {
		return <LoginResult>{
			errorMessage: message,
			ok: false,
			passwordExpired: true,
		};
	}
}

@Injectable({
	providedIn: 'root',
})
export class AuthenticationService {
	public get username(): string {
		const userInfo: UserInfo = JSON.parse(sessionStorage.getItem(AuthenticationService.KEY_CURRENT_USER));
		if (userInfo) {
			return userInfo.username;
		}

		return undefined;
	}

	get defaultUserName(): string {
		return localStorage.getItem(AuthenticationService.KEY_DEFAULT_USER_NAME);
	}

	set defaultUserName(value: string) {
		if (value) {
			localStorage.setItem(AuthenticationService.KEY_DEFAULT_USER_NAME, value);
		} else {
			localStorage.removeItem(AuthenticationService.KEY_DEFAULT_USER_NAME);
		}
	}

	get defaultLoginScheme(): string {
		return localStorage.getItem(AuthenticationService.KEY_DEFAULT_LOGIN_SCHEME);
	}

	set defaultLoginScheme(value: string) {
		localStorage.setItem(AuthenticationService.KEY_DEFAULT_LOGIN_SCHEME, value);
	}
	private static RESULT_INVALID = 'invalid_grant';
	private static RESULT_PASSWORD_EXPIRED = 'PasswordExpired';
	private static RESULT_INVALID_APPLICATION = 'InvalidApplication';
	private static RESULT_PASSWORD_NOT_CHANGED = 'PasswordNotChanged';
	private static RESULT_INVALID_MODE = 'InvalidMode';

	private static loginUrl = '/connect/token';

	private static LOGIN_FAILED_MSG = 'Login failed';

	public static KEY_DEFAULT_USER_NAME = 'defaultUsername';
	public static KEY_CURRENT_USER = 'currentUser';
	public static KEY_REFRESH_TOKEN = 'refreshToken';
	public static KEY_DEFAULT_LOGIN_SCHEME = 'defaultLoginScheme';
	public static KEY_IN_PASSWORD_RESET_FLOW = 'custom.recovery.password.flow';
	public static TOKEN_NAME = 'access_token';

	public static LOGIN_STYLE_LEGACY = 'legacy';
	public static LOGIN_STYLE_B2C = 'b2c';
	public static LOGIN_STYLE_B2C_CORP = 'b2c-corp';

	private isSecure: boolean;
	private _refreshToken: string;

	public loggedInB2C: boolean;
	public token: string;
	private readonly _destroying$ = new Subject<void>();

	public isAuthenticated$ = new BehaviorSubject(false);
	public isPracticeMode$ = new BehaviorSubject(false);
	public aadB2CLoginProgress$ = new BehaviorSubject('');
	public isAadB2CLoginInProgress$ = new BehaviorSubject(false);
	public static cookieServiceX: UnmanagedCookieService

	constructor(
		private http: HttpClient,
		private configService: ConfigService,
		private loggerService: LoggerService,
		private cookieService: UnmanagedCookieService,
		private sessionStorageManagementService: SessionStorageManagementService,
		private logoutService: LogoutService,
		private checkSaveChanges: CheckSaveChangesService,
		private jwtHelper: JwtHelperService,
		private msalService: MsalService,
		private broadcastService: MsalBroadcastService,
		private router: Router,
		private snackBar: MatSnackBar
	) {
		// set token if saved in local storage
		const currentUser = this.getUserInfo();
		this.token = currentUser && currentUser.token;

		this._refreshToken = sessionStorageManagementService.getItem(AuthenticationService.KEY_REFRESH_TOKEN);

		let baseUri = document.baseURI;
		this.isSecure = baseUri.startsWith('https');

		const config = this.configService.config;

		this.isAuthenticated$.next(this.getAuthenticated());
		this.isPracticeMode$.next(config && config.isPractice);

		// AAD B2C
		if (this.msalService.instance.getActiveAccount()) {
			this.loggedInB2C = true;
		} else {
			this.loggedInB2C = false;
		}

		AuthenticationService.cookieServiceX = cookieService;
	}

	setAadB2CProgress(msg: string) {
		this.aadB2CLoginProgress$.next(msg);
	}

	setAadB2CLoginInProgress(inProgress: boolean) {
		if (!inProgress) {
			this.loggedInB2C = false;
		}
		this.isAadB2CLoginInProgress$.next(inProgress);
	}

	assignToken(token: any) {

		this.setAadB2CProgress('Login successful... looking up user...');
		this.loggedInB2C = true;
		this.setAccessToken (token);

		this.http
			.get('api/b2c')
			.pipe(
				catchError((e) => {
					let msg = AuthenticationService.LOGIN_FAILED_MSG;
					if (e.error && e.error.error_description) {
						msg += ` - ${e.error.error_description}`;
					}
					this.resetB2CLogin(msg);

					return throwError(e);
				})
			)
			.subscribe((obj) => {
				let tokenObj = obj as TokenResult;

				const accessToken: string = tokenObj.access_token;
				this.setAccessToken (accessToken);
				this.setAadB2CProgress('Login successful... getting your configuration...');

				this.configService
					.getConfig()
					.toPromise()
					.then((config) => {
						// Successfully got the user config
						if (config.forceReloadClient) {
							let staleClientResult = LoginResult.Error(
								'A new version is required - page will refresh.'
							);
							staleClientResult.forceReload = true;
							return staleClientResult;
						}

						this.afterSuccessfulLogin(config, tokenObj, 'b2c-login');

						this.setAadB2CProgress('Login complete');
						this.setAadB2CLoginInProgress(false);

						this.defaultLoginScheme = AuthenticationService.LOGIN_STYLE_B2C;

						this.router.navigate(['/']);
					})
					.catch((e) => {
						this.resetB2CLogin(AuthenticationService.LOGIN_FAILED_MSG);
					});
			});
	
	}

	getUserInfo(): UserInfo {
		const currentUser: UserInfo = JSON.parse(sessionStorage.getItem(AuthenticationService.KEY_CURRENT_USER));
		return currentUser;
	}

	/**
	 * Submits a request to change the user's password.
	 * @param {string} username The username.
	 * @param {string} oldPassword  The old password.
	 * @param {string} newPassword The new password.
	 * @returns {Observable<LoginResult>} The result.
	 * @memberOf AuthenticationService
	 */
	changePassword(username: string, oldPassword: string, newPassword: string): Observable<LoginResult> {
		return this.login(username, oldPassword, true, newPassword);
	}

	login(
		username: string,
		password: string,
		changingPassword: boolean = false,
		newPassword: string = undefined
	): Observable<LoginResult> {
		this.logoutService.loggingOut = false;

		let body = `grant_type=password&username=${username}&password=${password}`;
		if (changingPassword) {
			/*
				When changing password combine the old and new passwords separated by a null character, to
				be separated at the server. This ensures that suppressed logging for the password on the server
				also applies to the new password.

				Note that this flow isn't part of the OAuth2 spec - so it's non-standard, but the spec doesn't
				cover changing passwords anyway.
			*/
			const combinedPassword = `${password}\0${newPassword}`;
			body = `grant_type=password&username=${username}&password=${combinedPassword}&changing_password=true`;
		}

		const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

		let tokenObj: TokenResult = undefined;

		const handleLoginError = (err: HttpErrorResponse): Observable<LoginResult> => {
			let msg: string;

			// Login failed so ensure nothing is left in local storage.
			this.cleanUpLocalStorage();

			let loginResult;

			if (err.status >= 500) {
				this.loggerService.logError(err);
				msg = 'Server is currently unavailable.';
			} else if (err.status === 400) {
				const errorResult = err.error;
				const code = errorResult && errorResult.error;
				if (code === AuthenticationService.RESULT_INVALID) {
					msg = errorResult.error_description;
				} else if (code === AuthenticationService.RESULT_PASSWORD_EXPIRED) {
					loginResult = LoginResult.PasswordExpired(errorResult.error_description);
					return of(loginResult);
				} else if (code === AuthenticationService.RESULT_INVALID_APPLICATION) {
					loginResult = LoginResult.Error(errorResult.error_description);
					return of(loginResult);
				} else if (code === AuthenticationService.RESULT_INVALID_MODE) {
					loginResult = LoginResult.Error(errorResult.error_description);
					return of(loginResult);
				} else if (code === AuthenticationService.RESULT_PASSWORD_NOT_CHANGED) {
					loginResult = LoginResult.Error(errorResult.error_description);
					return of(loginResult);
				}
			}

			if (!msg) {
				msg = 'An error occurred while logging in. Please try again.';
			}

			loginResult = LoginResult.Error(msg);
			return of(loginResult);
		};

		/*
			Chained requests...

			1. Get the client version from the server and check. If invalid, return a force reload result.
			2. Login and expect back a token. Any error is a login failure, but the specific error needs to be interpreted.
			3. On successful login, get the user config and system preferences.

			At the end, return the LoginResult object.

			On success, the JWT will have been stored, and the user config will have been stored,
			and the JWT set up to refresh.
		*/
		const result = this.configService.getMinVersion().pipe(
			map((serverVersion) => {
				const getVersionParts = (vs: string) => vs.split('.').map((p: string) => parseInt(p, 10));

				const thisVersion = environment.PACKAGE.version;
				let thisVersionParts = getVersionParts(thisVersion);
				let expectedVersionParts = getVersionParts(serverVersion);

				if (thisVersionParts.length !== expectedVersionParts.length) {
					return false;
				}

				for (let i = 0; i < thisVersionParts.length; i++) {
					if (thisVersionParts[i] < expectedVersionParts[i]) {
						return false;
					}
				}

				return true;
			}),
			mergeMap((r: boolean) => {
				if (!r) {
					const forceReloadResult = LoginResult.Error('Old version - browser needs to be refreshed');
					forceReloadResult.forceReload = true;
					return of(forceReloadResult);
				}
				return this.http.post(AuthenticationService.loginUrl, body, { headers: headers }).pipe(
						mergeMap((obj) => {
							tokenObj = obj as TokenResult;

						// Store the access token into local storage so AuthHttp can use it for the getConfig request,
						// and so it's available between page refreshes.
						const access_token = tokenObj.access_token;
						this.setAccessToken (access_token);

						return this.configService.getConfig();
					}),
					map((config: UserConfigDto) => {
						// Successfully got the user config
						if (config.forceReloadClient) {
							let staleClientResult = LoginResult.Error('A new version is required - page will refresh.');
							staleClientResult.forceReload = true;
							return staleClientResult;
						}

						this.afterSuccessfulLogin(config, tokenObj, username);

						// Return a successful LoginResult object.
						const loginResult = LoginResult.OK();
						return loginResult;
					}),
					catchError(handleLoginError)
				);
			})
		);

		return result;
	}

	logout(): void {
		// clear token remove user from local storage to log user out
		this.token = null;
		this._refreshToken = null;

		// Ensure the snackbar isn't left open when logged out.
		this.snackBar.dismiss();

		this.cleanUpLocalStorage();
		this.cookieService.delete('user');

		this.isAuthenticated$.next(false);
		this.setAadB2CProgress('');
		this.setAadB2CLoginInProgress(false);

		this.checkSaveChanges.reset();
	}

	getUserId(): number {
		let userInfo = this.getUserInfo();
		let result = this.jwtHelper.decodeToken(userInfo.token);

		// This is a big hack, to try to work around the cookie being missing when downloading a file.
		// If there's no cookie, write it again.
		let userGuid = this.cookieService.get('user');
		if (!userGuid) {
			userGuid = result['urn:userGuid'];
			this.cookieService.set('user', userGuid, null, '/', null, this.isSecure);
		}

		let userId = parseInt(result.sub, 10);
		return userId;
	}

	refreshToken() {
		const userInfo = this.getUserInfo();
		const body = `grant_type=refresh_token&refresh_token=${this._refreshToken}`;

		const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

		return this.http.post(AuthenticationService.loginUrl, body, { headers }).pipe(
			tap((token: TokenResult) => {
				if (!userInfo) {
					return;
				}

				userInfo.token = this.token = JSON.stringify(token);
				sessionStorage.setItem(AuthenticationService.KEY_CURRENT_USER, JSON.stringify(userInfo));
				console.log('refreshToken')	
				this.setAccessToken (token.access_token);

				this._refreshToken = token.refresh_token;
				sessionStorage.setItem(AuthenticationService.KEY_REFRESH_TOKEN, token.refresh_token);
			})
		);
	}

	public getToken() {
		return this.cookieService.get(AuthenticationService.TOKEN_NAME);
	}

	public static getToken() {
		return AuthenticationService.cookieServiceX.get(AuthenticationService.TOKEN_NAME);
	}

	get isAuthenticated() {
		return this.isAuthenticated$.value;
	}

	private cleanUpLocalStorage() {
		sessionStorage.removeItem(AuthenticationService.KEY_CURRENT_USER);
		sessionStorage.removeItem(AuthenticationService.KEY_REFRESH_TOKEN);
		this.sessionStorageManagementService.clearVolatileData();
		this.cookieService.delete(AuthenticationService.TOKEN_NAME);
		this.configService.clear();
	}

	private getAuthenticated(): boolean {
		const currentUser = this.getUserInfo();
		return !!currentUser;
	}

	private resetB2CLogin(msg: string) {
		this.loggedInB2C = false;
		this.setAadB2CProgress(msg);
		this.setAadB2CLoginInProgress(false);
	}

	private afterSuccessfulLogin(config: UserConfigDto, tokenObj: TokenResult, username: string) {
		const refresh_token = tokenObj.refresh_token,
			userInfo = new UserInfo(username, JSON.stringify(tokenObj));

		this._refreshToken = refresh_token;

		// Store user info in local storage.
		sessionStorage.setItem(AuthenticationService.KEY_CURRENT_USER, JSON.stringify(userInfo));
		sessionStorage.setItem(AuthenticationService.KEY_REFRESH_TOKEN, refresh_token);

		// // Set up to refresh the JWT
		// Store a cookie with the user GUID
		this.cookieService.delete('user');
		// Store a secure cookie if our base URL is secure.
		this.cookieService.set('user', config.userGuid, null, '/', null, this.isSecure);

		this.isPracticeMode$.next(config.isPractice);
		this.isAuthenticated$.next(true);
	}

	private setAccessToken (accessToken : string){
		this.cookieService.set(AuthenticationService.TOKEN_NAME, accessToken, null, '/', null, this.isSecure);
}
}
