import { Injectable, Inject } from '@angular/core';
import { MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalConfigurationService } from './msal-configuration.service';

/*
 * Needed to force an update to the MS Authentication parameters
 */

@Injectable({
  providedIn: 'root'
})
export class MsalDynamicConfigService {
  constructor(
    @Inject(MSAL_INSTANCE) private msalInstance: PublicClientApplication,
    private configService: MsalConfigurationService
  ) {
    const config = this.configService.getConfig();
  	const currentConfig = this.msalInstance.getConfiguration();

    currentConfig.auth.clientId = config.clientId;
    currentConfig.auth.redirectUri = config.redirectUri;
    currentConfig.auth.knownAuthorities = config.knownAuthorities;
    //Note that config.authority is set in login.components (as it changes depending on external / interna; user)
  }
}