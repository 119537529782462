import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { of } from 'rxjs';

export interface IConfig {
	customisations?: ICustomisation;
	options?: IOptions;
}

export interface ICustomisation {
	toolbarBackgroundColor?: string;
	toolbarColor: string;
	appTitle?: string;
	iconSvg?: string;
	logoSvg?: string;
	showCopyright?: boolean;
	accessibility?: boolean;
}

export interface IOptions {
	enableB2cLogin?: boolean;
	enableB2cCorporateLogin?: boolean;
	disableLegacyLogin?: boolean;
}

@Injectable({
	providedIn: 'root'
})
export class CustomisationService {
	customisation = {} as ICustomisation;
	options = {} as IOptions;
	
	constructor(private http: HttpClient, private title: Title) { }

	loadConfigurationData(): Promise<IConfig> {
		return this.http
			.get<IConfig>('./assets/app-config.json')
			.pipe(
				tap(r => {
					const customisations = r.customisations;
					const options = r.options;
					
					if (customisations) {
						this.customisation = customisations;
						
						if (customisations && customisations.appTitle) {
							this.title.setTitle(customisations.appTitle);
						}
					}
					if (options) {
						this.options = options;
					}
				}),
				catchError(() => {
					// Handle any error as having no customisation.
					// Likely to be a 404.
					return of({} as IConfig);
				})
			)
			.toPromise();
	}
}
