import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface IActiveDirectoryB2CConfig {
	clientId: string;
	scopes?: string;
	signInAuthority: string;
	signInAuthorityCorp: string;
	passwordResetAuthority: string;
	redirectUri: string;
	knownAuthorities: string[];
}

@Injectable({
	providedIn: 'root'
})
export class MsalConfigurationService {
	private configUrl = './assets/msal-config.json';
  	private config: any;

  	constructor(private http: HttpClient) {
	}

	loadConfig(): Observable<any> {
		return this.http.get(this.configUrl);
	}

	getConfig(): IActiveDirectoryB2CConfig {
		return this.config;
	}

	setConfig(config: any): void {
		this.config = config;
	}
}
